<template>
  <div class="px-12 pt-8 relative">
    <h2 class="text-base font-semibold leading-6 text-gray-900">Upcoming Appointments: {{ formattedSelectedDate }}</h2>
    <div v-if="isLoading" class="absolute inset-0 z-50">
      <LoadingOverlay :show="isLoading" />
    </div>
    <div v-else class="lg:grid lg:grid-cols-12 lg:gap-x-16">
      <div class="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
        <div class="flex items-center text-gray-900">
          <button @click="prevMonth" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <div class="flex-auto text-sm font-semibold">{{ currentMonth }}</div>
          <button @click="nextMonth" type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          <button v-for="(day, dayIdx) in days" :key="day.date || dayIdx" type="button" @click="day.date && selectDate(day.date)" :class="dayClasses(day, dayIdx)">
            <time v-if="day.date" :datetime="day.date" :class="timeClasses(day)">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
          </button>
        </div>
      </div>
      <ol class="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
        <li v-for="appointment in sortedAppointments" :key="appointment.account_id" class="relative flex space-x-6 py-6 xl:static">
          <div class="flex-auto">
            <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0">
              <router-link :to="`/company/${appointment.account_id}`">{{ appointment.business_name }}</router-link>
            </h3>
            <dl class="mt-2 flex flex-col text-gray-500 xl:flex-row">
              <div class="flex items-start space-x-3">
                <dt class="mt-0.5">
                  <span class="sr-only">Client Name</span>
                  <span class="text-gray-400">Client:</span>
                </dt>
                <dd>{{ appointment.client_name }}</dd>
              </div>
              <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt class="mt-0.5">
                  <span class="sr-only">Phone</span>
                  <span class="text-gray-400">Phone:</span>
                </dt>
                <dd>{{ appointment.phone }}</dd>
              </div>
              <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt class="mt-0.5">
                  <span class="sr-only">Status</span>
                  <span class="text-gray-400">Status:</span>
                </dt>
                <dd>{{ appointment.status }}</dd>
              </div>
              <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt class="mt-0.5">
                  <span class="sr-only">Bucket</span>
                  <span class="text-gray-400">Week:</span>
                </dt>
                <dd>{{ appointment.bucket }}</dd>
              </div>
              <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt class="mt-0.5">
                  <span class="sr-only">Appointment Time</span>
                  <span class="text-gray-400">Time:</span>
                </dt>
                <dd>{{ formatTime(appointment.next_appointment) }}</dd>
              </div>
            </dl>
          </div>
          <Menu as="div" class="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center">
            <div>
              <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                <span class="sr-only">Open options</span>
                <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a @click.prevent="openClientReachedDialog(appointment)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Client Reached</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click.prevent="MissedCall(appointment)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">
                      <span v-if="isProcessing && processingAppointment === appointment.account_id">
                        <svg class="animate-spin h-4 w-4 text-gray-500 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                      </span>
                      <span v-else>Note Missed Call</span>
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a @click.prevent="openRescheduleModal(appointment)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer']">Reschedule</a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </li>
      </ol>
    </div>
    <ClientReachedDialog v-if="showClientReachedDialog" :show="showClientReachedDialog" :appointment="currentAppointment" @close="closeClientReachedDialog" @save="saveClientReached"/>
    <RescheduleModal v-if="showRescheduleModal" :show="showRescheduleModal" :appointment="currentAppointment" @close="closeRescheduleModal" @save="saveRescheduled"/>
    <LoadingOverlay :show="isLoading || isProcessing" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import axios from 'axios';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon
} from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { format, startOfToday, addMonths, subMonths, parseISO, isSameMonth, isToday, getDay, add } from 'date-fns';
import ClientReachedDialog from './ClientReachedDialog.vue';
import RescheduleModal from './RescheduleModal.vue';
import LoadingOverlay from './LoadingOverlay.vue';

const appointments = ref([]);
const days = ref([]);
const currentMonth = ref(format(new Date(), 'MMMM yyyy'));
const selectedDate = ref(startOfToday());
const appointmentDays = ref([]);
const isLoading = ref(false);
const isProcessing = ref(false);
const processingAppointment = ref(null);
const showClientReachedDialog = ref(false);
const showRescheduleModal = ref(false);
const currentAppointment = ref(null);

const user = { id: 1 }; // Replace with the actual user ID fetching logic

const loadAppointments = async (date) => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company');
  try {
    const response = await axios.get('/appointments', {
      params: {
        date: format(date, 'yyyy-MM-dd'),
        org_id: orgId,
      },
    });
    appointments.value = response.data.map(client => {
      let clientData;
      switch (orgId) {
        case '1':
          clientData = client.focus_client;
          break;
        case '2':
          clientData = client.choice_client;
          break;
        case '3':
          clientData = client.relmedia_client;
          break;
        default:
          clientData = {};
      }
      return {
        account_id: client.account_id,
        business_name: clientData.business_name,
        client_name: clientData.client_name,
        phone: clientData.phone,
        status: clientData.status,
        bucket: client.bucket,
        next_appointment: client.next_appointment,
      };
    });
    appointments.value.sort((a, b) => new Date(a.next_appointment) - new Date(b.next_appointment));
  } catch (error) {
    console.error('Error fetching appointments:', error);
  }
  isLoading.value = false;
};

const loadAppointmentDays = async () => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company');
  try {
    const response = await axios.get('/onboarding', {
      params: {
        org_id: orgId,
      },
    });
    const appointmentList = response.data
      .filter(client => client.bucket !== 0) // Filter out any appointments with a bucket of 0
      .map(client => {
        return format(parseISO(client.next_appointment), 'yyyy-MM-dd');
      });
    appointmentDays.value = [...new Set(appointmentList)]; // Remove duplicates
  } catch (error) {
    console.error('Error fetching appointment days:', error);
  }
  isLoading.value = false;
};


const generateDays = (month) => {
  const startDay = new Date(month.getFullYear(), month.getMonth(), 1);
  const endDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
  const daysArray = [];

  // Add blank days to align the first day of the month correctly
  const blankDaysCount = (getDay(startDay) + 6) % 7; // Adjusting for Monday start
  for (let i = 0; i < blankDaysCount; i++) {
    daysArray.push({});
  }

  for (let day = startDay; day <= endDay; day.setDate(day.getDate() + 1)) {
    const dateStr = format(new Date(day), 'yyyy-MM-dd');
    const hasAppointment = appointmentDays.value.includes(dateStr);
    daysArray.push({
      date: dateStr,
      isCurrentMonth: isSameMonth(day, new Date()),
      isToday: isToday(day),
      isSelected: isSameMonth(day, selectedDate.value),
      hasAppointment,
    });
  }
  days.value = daysArray;
};

const formattedSelectedDate = computed(() => {
  return format(selectedDate.value, 'MMMM dd, yyyy');
});

const dayClasses = (day, dayIdx) => {
  return [
    'py-1.5 hover:bg-gray-100 focus:z-10',
    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
    (day.isSelected || day.isToday) && 'font-semibold',
    day.isSelected && 'text-white',
    !day.isSelected && day.isCurrentMonth && 'text-gray-900',
    !day.isSelected && !day.isCurrentMonth && 'text-gray-400',
    day.isToday && 'text-indigo-600',
    dayIdx === 0 && 'rounded-tl-lg',
    dayIdx === 6 && 'rounded-tr-lg',
    dayIdx === days.value.length - 7 && 'rounded-bl-lg',
    dayIdx === days.value.length - 1 && 'rounded-br-lg'
  ];
};

const timeClasses = (day) => {
  return [
    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
    day.hasAppointment && 'bg-yellow-400',
    day.isSelected && day.isToday && 'bg-indigo-600',
    day.isSelected && !day.isToday && 'bg-gray-900'
  ];
};

const selectDate = (date) => {
  selectedDate.value = parseISO(date);
  loadAppointments(selectedDate.value);
};

const prevMonth = () => {
  const prev = subMonths(selectedDate.value, 1);
  selectedDate.value = prev;
  currentMonth.value = format(prev, 'MMMM yyyy');
  generateDays(prev);
};

const nextMonth = () => {
  const next = addMonths(selectedDate.value, 1);
  selectedDate.value = next;
  currentMonth.value = format(next, 'MMMM yyyy');
  generateDays(next);
};

const noteMissedCall = async (appointment) => {
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  isProcessing.value = true;
  processingAppointment.value = appointment.account_id;
  try {
    const response = await axios.post('/onboarding-history', {
      org_id: orgId,
      account_id: appointment.account_id,
      type: 'note',
      data: "Missed call noted for client",
      owner_id: user.id
    });
    console.log('Missed call noted:', response.data);
    // Optionally, you can add the new note to the appointments array or handle the UI update
  } catch (error) {
    console.error('Error noting missed call:', error);
  }
  isProcessing.value = false;
  processingAppointment.value = null;
};

const MissedCall = (appointment) => {
  noteMissedCall(appointment);
};

const openClientReachedDialog = (appointment) => {
  currentAppointment.value = appointment;
  showClientReachedDialog.value = true;
};

const closeClientReachedDialog = () => {
  showClientReachedDialog.value = false;
};

const saveClientReached = async ({ clientFeeling, notes, appointment }) => {
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  const nextAppointment = format(add(new Date(), { weeks: 1 }), 'yyyy-MM-dd HH:mm:ss');

  isProcessing.value = true;
  processingAppointment.value = appointment.account_id;

  try {
    // Log the data being sent
    console.log({
      org_id: orgId,
      status: clientFeeling,
      next_appointment: nextAppointment,
      bucket: appointment.bucket + 1 // Increment the bucket by 1
    });

    // Add the notes to OnboardingHistory
    await axios.post('/onboarding-history', {
      org_id: orgId,
      account_id: appointment.account_id,
      type: 'note',
      data: notes,
      owner_id: user.id
    });

    // Update the Onboarding table
    await axios.put(`/onboarding/${appointment.account_id}`, {
      org_id: orgId,
      status: clientFeeling,
      next_appointment: nextAppointment,
      bucket: appointment.bucket + 1 // Increment the bucket by 1
    });

    // Remove the appointment from the list
    appointments.value = appointments.value.filter(a => a.account_id !== appointment.account_id);

    console.log('Client reached notes saved and onboarding updated.');
    location.reload();
  } catch (error) {
    console.error('Error saving client reached notes and updating onboarding:', error);
  }

  isProcessing.value = false;
  processingAppointment.value = null;
  closeClientReachedDialog();
};

const openRescheduleModal = (appointment) => {
  currentAppointment.value = appointment;
  showRescheduleModal.value = true;
};

const closeRescheduleModal = () => {
  showRescheduleModal.value = false;
};

const saveRescheduled = async ({ newDateTime, appointment }) => {
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  isProcessing.value = true;
  processingAppointment.value = appointment.account_id;

  try {
    await axios.put(`/onboarding/${appointment.account_id}`, {
      org_id: orgId,
      next_appointment: newDateTime,
      bucket: appointment.bucket
    });

    // Update the appointment time locally
    const updatedAppointments = appointments.value.map(a => {
      if (a.account_id === appointment.account_id) {
        a.next_appointment = newDateTime;
      }
      return a;
    });
    appointments.value = updatedAppointments.sort((a, b) => new Date(a.next_appointment) - new Date(b.next_appointment));

    console.log('Appointment rescheduled.');
    location.reload(); // Reload the page to refresh the calendar
  } catch (error) {
    console.error('Error rescheduling appointment:', error);
  }

  isProcessing.value = false;
  processingAppointment.value = null;
  closeRescheduleModal();
};

const formatTime = (date) => {
  return format(parseISO(date), 'hh:mm a');
};

const sortedAppointments = computed(() => {
  return appointments.value.sort((a, b) => new Date(a.next_appointment) - new Date(b.next_appointment));
});

onMounted(async () => {
  await loadAppointments(selectedDate.value);
  await loadAppointmentDays();
  generateDays(selectedDate.value);
});

watch(appointmentDays, (newVal) => {
  generateDays(new Date(currentMonth.value));
});
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
