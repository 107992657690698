<template>
  <div class="relative">
    <div v-if="isLoading" class="absolute inset-0 z-50">
      <LoadingOverlay :show="isLoading" />
    </div>
    <div class="max-w-4xl mx-auto my-5 border rounded-2xl px-6 py-6 bg-white">
      <div class="flex justify-between items-center px-4 sm:px-0">
        <div>
          <h3 class="text-base font-semibold leading-7 text-gray-900">Company Profile</h3>
          <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Company details and information.</p>
        </div>
        <div class="flex space-x-4">
          <button @click="markDoNotCall" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Do Not Call
          </button>
          <button @click="markCalled" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Mark Called
          </button>
        </div>
      </div>
      <div class="mt-6">
        <dl class="grid grid-cols-1 sm:grid-cols-2">
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Business Name</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.business_name }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Client Name</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.client_name }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Email Address</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.email }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Phone</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.phone }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">State</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.state }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Industry</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.industry }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-gray-900">Status</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ company.status }}</dd>
          </div>
          <div class="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-0">
            <div class="flex justify-between items-center">
              <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
              <button @click="openModal" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add Note
              </button>
            </div>
            <dd class="mt-2 text-sm text-gray-900">
              <div class="overflow-x-auto">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Note
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Owner
                      </th>
                      <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="note in sortedNotes" :key="note.id">
                      <td class="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">
                        {{ note.data }}
                      </td>
                      <td class="px-6 py-4 whitespace-normal text-sm text-gray-500">
                        {{ note.owner_name }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                        {{ formatDate(note.date_created) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <AddNoteModal v-if="showModal" @close="closeModal" @addNote="addNote" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import AddNoteModal from './AddNoteModal.vue';
import LoadingOverlay from './LoadingOverlay.vue';
import { format } from 'date-fns';

const route = useRoute();
const company = ref({
  business_name: '',
  client_name: '',
  email: '',
  phone: '',
  state: '',
  industry: '',
  status: '',
  notes: [] // Ensure this is always an array
});
const notes = ref([]);
const showModal = ref(false);
const isLoading = ref(false);

const user = { id: 1 }; // Replace with actual user ID fetching logic

const fetchCompanyData = async () => {
  const companyId = route.params.id;
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  isLoading.value = true;
  try {
    const response = await axios.get(`/company/${companyId}`, {
      params: {
        org_id: orgId
      }
    });
    company.value = response.data;
    // Ensure notes is an array if it's not already set properly
    if (!Array.isArray(company.value.notes)) {
      company.value.notes = [];
    }
  } catch (error) {
    console.error('Error fetching company data:', error);
  } finally {
    isLoading.value = false;
  }
};

const fetchNotes = async () => {
  const companyId = route.params.id;
  isLoading.value = true;
  try {
    const response = await axios.get('onboarding-history', {
      params: {
        account_id: companyId
      }
    });
    notes.value = response.data;
  } catch (error) {
    console.error('Error fetching notes:', error);
  } finally {
    isLoading.value = false;
  }
};

const openModal = () => {
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const addNote = async (note) => {
  const companyId = route.params.id;
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  isLoading.value = true;
  try {
    const response = await axios.post('onboarding-history', {
      org_id: orgId,
      account_id: companyId,
      data: note,
      owner_id: user.id // Include the user ID
    });
    notes.value.unshift(response.data); // Add the new note to the top of the notes array
  } catch (error) {
    console.error('Error adding note:', error);
  } finally {
    isLoading.value = false;
    showModal.value = false;
  }
};

const markCalled = async () => {
  const companyId = route.params.id;
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  isLoading.value = true;
  try {
    const response = await axios.post('onboarding-history', {
      org_id: orgId,
      account_id: companyId,
      type: 'Call',
      data: "Customer was called but didn't reach them",
      owner_id: user.id // Include the user ID
    });
    notes.value.unshift(response.data); // Add the new note to the top of the notes array
  } catch (error) {
    console.error('Error marking call:', error);
  } finally {
    isLoading.value = false;
  }
};

const markDoNotCall = async () => {
  const companyId = route.params.id;
  const orgId = localStorage.getItem('company'); // Get the org_id from local storage
  const nextAppointment = company.value.next_appointment 
    ? new Date(company.value.next_appointment).toISOString().slice(0, 19).replace('T', ' ') 
    : new Date().toISOString().slice(0, 19).replace('T', ' '); // Use existing or set to today's date formatted for MySQL
  isLoading.value = true;
  try {
    await axios.put(`/onboarding/${companyId}`, {
      org_id: orgId,
      bucket: 0,
      status: 'DNC',
      next_appointment: nextAppointment // Ensure we keep or set the next_appointment date
    });
    company.value.bucket = 0;
    company.value.status = 'DNC';
    company.value.next_appointment = nextAppointment;
  } catch (error) {
    console.error('Error updating to Do Not Call:', error);
  } finally {
    isLoading.value = false;
  }
};

const formatDate = (date) => {
  return format(new Date(date), 'MMMM dd, yyyy - hh:mm a');
};

const sortedNotes = computed(() => {
  return notes.value.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
});

onMounted(() => {
  fetchCompanyData();
  fetchNotes();
});
</script>

<style scoped>
/* Custom styles can be added here if needed */
</style>
