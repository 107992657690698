<template>
  <div class="backimg"></div>
  <div class="content-container">
    <div class="max-w-xs mx-auto">
      <img class="h-32 object-contain" alt="logo" src="/assets/logo.png">
      <h1 class="text-2xl font-bold mb-4">Forgot Password</h1>
      <h4 class="text-lg mb-8">Request a Password Reset Link.</h4>
      <form class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <input
              id="email"
              type="email"
              placeholder="Enter your email"
              v-model="account.email"
              @blur="validateEmail"
              :class="{'border-yellow-500': emailError}"
              class="w-full px-3 py-2 border rounded-md"
          />
          <div v-if="emailError" class="txt-custom-color text-xs">{{ emailError }}</div>
        </div>
        <button
            @click="performLogin"
            :disabled="isLoggingIn"
            class="w-full btn-custom-color text-white p-2 rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import AccountService from '@/services/AccountService';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      account: {
        email: ''
      },
      emailError: '',
      isLoggingIn: false,
      apiError: ''
    };
  },
  methods: {
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.account.email)) {
        this.emailError = 'Invalid email address';
      } else {
        this.emailError = '';
      }
    },
    async performLogin() {
      this.isLoggingIn = true;
      this.apiError = '';
      this.validateEmail();
      if (this.emailError) {
        this.isLoggingIn = false;
        return;
      }
      try {
        const success = await AccountService.forgotPassword(this.account);
        if (success) {
          this.$router.push({ path: '/login', query: { message: 'An email to reset your password has been sent!' } });
        }
      } catch (error) {
        alert('Unknown error occurred, please try again later.');
        this.apiError = 'Unknown error occurred, please try again later.';
      } finally {
        this.isLoggingIn = false;
      }
    }
  }
};
</script>


<style>
.v-enter-active, .v-leave-active {
  transition: opacity 3s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.btn-custom-color {
  background-color: #fbbb05; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}

.txt-custom-color {
  color: #fbbb05; /* Set text color to ensure visibility */
}


.btn-custom-color:hover {
  background-color: #e0a800; /* Slightly darker for hover effect */
}

.login-custom-color {
  background-color: #696969; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}


.login-custom-color:hover {
  background-color: #4c4c4c; /* Slightly darker for hover effect */
}


/* Add styles for the disabled state */
.btn-custom-color:disabled {
  background-color: #fbbb05; /* Maintain the background color */
  color: white; /* Maintain the text color */
  opacity: 0.7; /* Optional: Lower opacity to indicate it's disabled */
  cursor: not-allowed; /* Optional: Change cursor to indicate it's not clickable */
}

.backimg {
  opacity: 0.30;
  position: fixed; /* Changed from relative to fixed */
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Position it at the left of the viewport */
  width: 100vw; /* Span the entire viewport width */
  height: 100vh; /* Span the entire viewport height */
  z-index: -1; /* Place it behind other content */
  background: url('~@/assets/swirl_crop.jpg') no-repeat center center; /* Center the background */
  background-color: #FFF;
  background-size: cover; /* Cover the entire area of the div */
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  position: relative; /* Ensure it's positioned above the background */
  z-index: 1; /* Higher than the background */
}

</style>
