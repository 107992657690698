import AccountService from '@/services/AccountService';

export default {
    state: {
        account: {
            fullName: '',
            email: '',
            password: ''
        },
        token: localStorage.getItem('token') || ''
    },
    getters: {
        // Your getters, e.g., isAuthenticated
        isAuthenticated: state => !!state.token
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.account = account;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        }
    },
    actions: {
        async login({ commit }, account) {
            try {
                const response = await AccountService.login(account);
                if (response.data.token) {
                    commit('SET_TOKEN', response.data.token);
                    commit('SET_ACCOUNT', account);
                    // Set the user token using AccountService
                    AccountService.setUserToken(response.data.token);
                }
                console.log('returning response', response)
                return response;
            } catch (error) {
                return false
                console.error('An error occurred while logging in:', error);
            }
        },
        async register({ commit }, account) {
            try {
                const names = account.fullName.split(' ');
                const firstName = names[0];
                const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

                const apiAccount = {
                    first_name: firstName,
                    last_name: lastName,
                    email: account.email,
                    phone_number: account.phone,
                    address: account.address
                };

                const response = await AccountService.register(apiAccount);
                if (response.data.success) {
                    commit('SET_ACCOUNT', account);
                    return true;
                } else {
                    throw new Error('Unknown Error');
                }
            } catch (error) {
                console.error('An error occurred while registering:', error);
                throw error;
            }
        },

        async contactUs({ commit }, account) {
            try {
                const names = account.fullName.split(' ');
                const firstName = names[0];
                const lastName = names.length > 1 ? names.slice(1).join(' ') : '';

                const apiAccount = {
                    first_name: firstName,
                    last_name: lastName,
                    email: account.email,
                    phone_number: account.phone,
                    message: account.message
                };

                const response = await AccountService.contactUs(apiAccount);
                if (response.data.success) {
                    return true;
                } else {
                    new Error('Unknown Error. Please try again later.');
                }
            } catch (error) {
                console.error('An error occurred while registering:', error);
                throw error;
            }
        },

        async forgotPassword({ commit }, email) {
            try {
                await AccountService.forgotPassword(email);
                // Handle successful forgot password action
            } catch (error) {
                console.error('An error occurred while resetting the password:', error);
            }
        },
        async resetPassword({ commit }, info) {
            try {
                await AccountService.resetPassword(info);
                // Handle successful password reset
            } catch (error) {
                console.error('An error occurred while resetting the password:', error);
            }
        },
        // ... other actions for init etc., calling AccountService methods
    }
};
