import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';

// Import main CSS
import '/index.css';

axios.defaults.baseURL = process.env.VUE_APP_APIURL;

// Request interceptor to set the Authorization header dynamically
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Response interceptor to handle unauthenticated responses
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Clear token from local storage
            localStorage.removeItem('token'); // Remove the token from localStorage
            localStorage.removeItem('user_id'); // Remove the user_id from localStorage
            router.push({ name: 'login' }); // Redirect to /login page
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

createApp(App).use(store).use(router).mount('#app');
