<template>
    <div class="fixed inset-0 z-50 overflow-y-auto" v-if="show">
      <div class="flex items-center justify-center min-h-screen px-4">
        <div class="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-md">
          <div class="p-4">
            <h2 class="text-lg font-medium text-gray-900">Reschedule Appointment</h2>
            <div class="mt-4">
              <label for="date" class="block text-sm font-medium text-gray-700">Select Date</label>
              <input type="date" v-model="rescheduleDate" id="date" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
            </div>
            <div class="mt-4">
              <label for="time" class="block text-sm font-medium text-gray-700">Select Time</label>
              <input type="time" v-model="rescheduleTime" id="time" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm" />
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
            <button @click="save" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm">Save</button>
            <button @click="$emit('close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const props = defineProps({
    show: Boolean,
    appointment: Object
  });
  
  const emit = defineEmits(['close', 'save']);
  
  const rescheduleDate = ref('');
  const rescheduleTime = ref('');
  
  const save = () => {
    const newDateTime = `${rescheduleDate.value} ${rescheduleTime.value}`;
    emit('save', { newDateTime, appointment: props.appointment });
  };
  </script>
  
  <style scoped>
  /* Custom styles can be added here if needed */
  </style>
  