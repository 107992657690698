<template>
  <div class="relative px-4 mt-10 sm:px-6 lg:px-8">
    <LoadingOverlay :show="isLoading" />
    <div class="sm:flex sm:items-center justify-between">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold leading-6 text-gray-900">Client Status</h1>
        <p class="mt-2 text-sm text-gray-700">A list of all clients currently in the onboarding phases.</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none relative">
        <button @click="toggleFilterOptions" class="border rounded px-4 py-2 bg-gray-200">Filter Options</button>
        <div v-if="showFilterOptions" class="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md z-10">
          <div class="p-4">
            <label class="block text-sm font-medium text-gray-700 mb-2">Filter By:</label>
            <select v-model="selectedFilter" @change="resetFilterValues" class="border rounded px-4 py-2 w-full mb-4">
              <option value="">Select Filter</option>
              <option value="bucket">Bucket</option>
              <option value="mood">Mood</option>
              <option value="status">Status</option>
            </select>
            <div v-if="selectedFilter">
              <div v-if="selectedFilter === 'bucket'">
                <label class="block text-sm font-medium text-gray-700 mb-2">Bucket</label>
                <div v-for="bucket in uniqueBuckets" :key="bucket" class="flex items-center mb-2">
                  <input type="checkbox" :value="bucket" v-model="filterValues" class="mr-2" />
                  <label>{{ bucket }}</label>
                </div>
              </div>
              <div v-if="selectedFilter === 'mood'">
                <label class="block text-sm font-medium text-gray-700 mb-2">Mood</label>
                <div v-for="mood in uniqueMoods" :key="mood" class="flex items-center mb-2">
                  <input type="checkbox" :value="mood" v-model="filterValues" class="mr-2" />
                  <label>{{ mood }}</label>
                </div>
              </div>
              <div v-if="selectedFilter === 'status'">
                <label class="block text-sm font-medium text-gray-700 mb-2">Status</label>
                <div v-for="status in uniqueStatuses" :key="status" class="flex items-center mb-2">
                  <input type="checkbox" :value="status" v-model="filterValues" class="mr-2" />
                  <label>{{ status }}</label>
                </div>
              </div>
              <button @click="applyFilter" class="mt-4 px-4 py-2 border rounded bg-gray-200">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="-mx-4 mt-8 sm:-mx-0">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th @click="sortTable('business_name')" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 cursor-pointer">Business Name</th>
            <th @click="sortTable('client_name')" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell cursor-pointer">Client Name</th>
            <th @click="sortTable('bucket')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">Bucket</th>
            <th @click="sortTable('mood')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">Mood</th>
            <th @click="sortTable('status')" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer">Status</th>
            <th class="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span class="sr-only">Profile</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="client in paginatedClients" :key="client.email">
            <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
              {{ client.business_name }}
              <dl class="font-normal lg:hidden">
                <dt class="sr-only">Client Name</dt>
                <dd class="mt-1 truncate text-gray-700">{{ client.client_name }}</dd>
                <dt class="sr-only sm:hidden">Bucket</dt>
                <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ client.bucket }}</dd>
                <dt class="sr-only sm:hidden">Mood</dt>
                <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ client.mood }}</dd>
                <dt class="sr-only sm:hidden">Status</dt>
                <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ client.status }}</dd>
              </dl>
            </td>
            <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ client.client_name }}</td>
            <td class="px-3 py-4 text-sm text-gray-500">{{ client.bucket }}</td>
            <td class="px-3 py-4 text-sm text-gray-500">{{ client.mood }}</td>
            <td class="px-3 py-4 text-sm text-gray-500">{{ client.status }}</td>
            <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <a :href="`/company/${client.id}`" class="text-indigo-600 hover:text-indigo-900">Profile<span class="sr-only">, {{ client.client_name }}</span></a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Pagination -->
      <div class="mt-4 flex justify-between items-center">
        <div>
          <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border rounded bg-gray-200">Previous</button>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border rounded bg-gray-200 ml-2">Next</button>
        </div>
        <div>
          Page {{ currentPage }} of {{ totalPages }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue';

const clients = ref([]);
const isLoading = ref(false);
const selectedFilter = ref('');
const filterValues = ref([]);
const showFilterOptions = ref(false);
const sortKey = ref('business_name');
const sortOrder = ref('asc');
const currentPage = ref(1);
const pageSize = 20;

const uniqueBuckets = ref([]);
const uniqueMoods = ref([]);
const uniqueStatuses = ref([]);

const fetchClients = async () => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company');
  try {
    const response = await axios.get('/onboarding', {
      params: {
        org_id: orgId,
      },
    });
    clients.value = response.data.map(client => {
      const clientData = client.focus_client || client.choice_client || client.relmedia_client;
      return {
        id: clientData.id, // Assuming each client object has an `id` property
        business_name: clientData.business_name,
        client_name: clientData.client_name,
        bucket: client.bucket,
        mood: client.status, // Using status as mood
        status: clientData.status, // Keeping original status
      };
    });
    setUniqueFilterValues();
  } catch (error) {
    console.error('Error fetching onboarding data:', error);
  }
  isLoading.value = false;
};

const setUniqueFilterValues = () => {
  uniqueBuckets.value = [...new Set(clients.value.map(client => client.bucket))];
  uniqueMoods.value = [...new Set(clients.value.map(client => client.mood))];
  uniqueStatuses.value = [...new Set(clients.value.map(client => client.status))];
};

const filteredClients = computed(() => {
  if (!filterValues.value.length) return clients.value;

  return clients.value.filter(client => {
    if (selectedFilter.value === 'bucket') {
      return filterValues.value.includes(client.bucket);
    }
    if (selectedFilter.value === 'mood') {
      return filterValues.value.includes(client.mood);
    }
    if (selectedFilter.value === 'status') {
      return filterValues.value.includes(client.status);
    }
    return true;
  });
});

const sortedClients = computed(() => {
  return filteredClients.value.sort((a, b) => {
    let result = 0;
    if (a[sortKey.value] > b[sortKey.value]) {
      result = 1;
    } else if (a[sortKey.value] < b[sortKey.value]) {
      result = -1;
    }
    return sortOrder.value === 'asc' ? result : -result;
  });
});

const paginatedClients = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  const end = start + pageSize;
  return sortedClients.value.slice(start, end);
});

const totalPages = computed(() => {
  return Math.ceil(sortedClients.value.length / pageSize);
});

const sortTable = (key) => {
  if (sortKey.value === key) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortKey.value = key;
    sortOrder.value = 'asc';
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const toggleFilterOptions = () => {
  showFilterOptions.value = !showFilterOptions.value;
};

const applyFilter = () => {
  currentPage.value = 1; // Reset to the first page when applying a filter
  showFilterOptions.value = false; // Hide filter options after applying
};

const resetFilterValues = () => {
  filterValues.value = [];
};

onMounted(() => {
  fetchClients();
});
</script>

<style scoped>
/* Custom styles can be added here if needed */
</style>
