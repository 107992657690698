<template>
  <div class="max-w-4xl mx-auto my-5 border rounded-2xl px-6 py-6 bg-white relative">
    <h2 class="text-2xl font-semibold mb-5">Update User Info</h2>
    <div v-if="isLoading" class="absolute inset-0 z-50">
      <LoadingOverlay :show="isLoading" />
    </div>
    <form v-else @submit.prevent="editMode && updateProfile()">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- User Information -->
        <div class="md:col-span-2">
          <label class="block text-sm font-medium text-gray-700">Username</label>
          <input type="text" v-model="user.username" disabled class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">First Name</label>
          <input type="text" v-model="user.first_name" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Last Name</label>
          <input type="text" v-model="user.last_name" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Initials</label>
          <input type="text" v-model="user.initials" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Email Address</label>
          <input type="email" v-model="user.email" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Mobile Phone</label>
          <input type="tel" v-model="user.phone_number" :class="['input-field', { 'editable': editMode }]" :disabled="!editMode" @blur="validatePhone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          <p v-if="phoneError && editMode" class="text-red-500 text-xs mt-1">{{ phoneError }}</p>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Role</label>
          <input type="text" v-model="user.role" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm" disabled>
        </div>
        <!-- Password Change -->
        <div class="md:col-span-2" v-if="editMode">
          <h3 class="text-xl font-semibold mt-8 mb-5">New Password</h3>
        </div>
        <div class="md:col-span-2" v-if="editMode">
          <label class="block text-sm font-medium text-gray-700">New Password</label>
          <input type="password" v-model="user.newPassword" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
        </div>
        <div class="md:col-span-2" v-if="editMode">
          <label class="block text-sm font-medium text-gray-700">Confirm Password</label>
          <input type="password" @blur="validatePasswords" v-model="user.confirmPassword" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
          <p v-if="passwordError && editMode" class="text-red-500 text-xs mt-1">{{ passwordError }}</p>
        </div>
        <div class="md:col-span-2 text-right">
          <button v-if="!editMode" @click="toggleEditMode" class="mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">
            Edit
          </button>
          <button v-else @click="toggleEditMode" class="mt-6 px-6 py-2 mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Cancel
          </button>
          <button v-if="editMode" type="submit" class="mt-6 px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700">Update Profile</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue'; // Import your loading overlay component

export default {
  props: ['id'],
  components: {
    LoadingOverlay
  },
  data() {
    return {
      user: {
        username: '',
        first_name: '',
        last_name: '',
        role: '',
        initials: '',
        email: '',
        phone_number: '',
        newPassword: '',
        confirmPassword: ''
      },
      editMode: false,
      phoneError: '',
      passwordError: '',
      isLoading: false // Add loading state
    };
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (!this.editMode) {
        this.fetchUserInfo(); // Restore original data on cancel
        this.user.newPassword = '';
        this.user.confirmPassword = '';
      }
    },
    updateProfile() {
      if (this.validatePhone() && this.validatePasswords()) {
        axios.put(`/user/${this.id}`, this.user, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
          .then(response => {
            // Handle successful update
            alert('Profile successfully updated!');
            this.editMode = false; // Exit edit mode
            this.fetchUserInfo(); // Optionally fetch updated info
          })
          .catch(error => {
            // Handle error
            console.error('There was an error updating the profile:', error);
            alert('Failed to update profile.');
          });
      }
    },
    validatePhone() {
      const re = /^(\d{3}-\d{3}-\d{4}|\d{10})$/;
      if (!re.test(this.user.phone_number)) {
        this.phoneError = 'Invalid phone format. Use 000-000-0000 or 0000000000.';
        return false;
      }
      this.phoneError = '';
      return true;
    },
    validatePasswords() {
      if (this.user.newPassword !== this.user.confirmPassword) {
        this.passwordError = 'Passwords do not match.';
        return false;
      }
      this.passwordError = '';
      return true;
    },
    async fetchUserInfo() {
      this.isLoading = true; // Start loading
      try {
        const response = await axios.get(`/userprofile/${this.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        // Assuming the response has a data object with user information
        this.user.username = response.data.username;
        this.user.first_name = response.data.first_name;
        this.user.last_name = response.data.last_name;
        this.user.initials = response.data.initials;
        this.user.email = response.data.email;
        this.user.phone_number = response.data.phone_number;
        this.user.role = response.data.role_name;
      } catch (error) {
        console.error('There was an error fetching the user data:', error);
      } finally {
        this.isLoading = false; // End loading
      }
    }
  },
  mounted() {
    this.fetchUserInfo();
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
