<template>
  <div class="backimg"></div>
  <div class="content-container">
    <!-- Toast Message -->
    <transition name="v">
      <div v-if="showMessage && message" class="fixed top-0 right-0 m-4 p-4 bg-red-600 text-white rounded">
        {{ message }}
        <a href="#" class="ml-4 text-white underline" @click.prevent="closeMessage">Close</a>
      </div>
    </transition>

    <div class="flex flex-col">
      <div class="flex flex-col items-center mt-4">
        <img alt="logo" src="/assets/logo.png">
        <h4 class="text-lg mt-2">Please enter your credentials to log in</h4>
      </div>

      <!-- Standard Login Form -->
      <form @submit.prevent="performLogin" name="login-form" class="w-full max-w-sm mt-4">
        <div class="mb-4">
          <label for="email" class="block text-sm font-bold">Email</label>
          <input
            :disabled="isLoggingIn"
            type="email"
            id="email"
            v-model="account.email"
            @blur="validateEmail"
            :class="emailError ? 'txt-custom-color' : ''"
            class="mt-1 p-2 w-full border rounded-md"
            @keyup.enter="performLogin"
            autocomplete="username"
          />
          <p v-if="emailError" class="txt-custom-color text-xs">{{ emailError }}</p>
        </div>

        <div class="mb-4 text-left">
          <label for="password" class="block text-sm font-bold">Password</label>
          <input
            :disabled="isLoggingIn"
            type="password"
            id="password"
            v-model="account.password"
            @blur="validatePassword"
            :class="passwordError ? 'border-yellow-500' : ''"
            class="mt-1 p-2 w-full border rounded-md"
            @keyup.enter="performLogin"
            autocomplete="current-password"
          />
          <p v-if="passwordError" class="txt-custom-color text-xs">{{ passwordError }}</p>
          <a href="/forgot" class="self-end text-gray-500 text-sm self-end">Forgot Password?</a>
        </div>

        <div class="mb-4 text-left">
          <label for="company" class="block text-sm font-bold">Company</label>
          <select
            id="company"
            v-model="account.company"
            class="mt-1 p-2 w-full border rounded-md"
          >
            <option value="1">Focus</option>
            <option value="2">ChoiceMedia</option>
            <option value="3">RelMedia</option>
          </select>
        </div>

        <p v-if="apiError" class="txt-custom-color text-xs">{{ apiError }}</p>
        <button :disabled="isLoggingIn" class="mt-4 w-full px-4 py-2 login-custom-color text-white rounded hover:bg-opacity-90">Login</button>
      </form>

      <span class="ml-10 text-sm text-gray-400">Don't have an account?
        <a href="/contactus" class="self-end txt-custom-color text-sm font-bold">Contact Us</a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CombinedLogin',
  computed: {
    message() {
      return this.$route.query.message;
    }
  },
  methods: {
    closeMessage() {
      this.showMessage = false;
    },
    ...mapActions(['login']),
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.account.email)) {
        this.emailError = 'Invalid email address';
      } else {
        this.emailError = '';
      }
    },
    validatePassword() {
      if (this.account.password.length < 8) {
        this.passwordError = 'Password must be at least 8 characters long';
      } else {
        this.passwordError = '';
      }
    },
    async performLogin() {
      this.apiError = '';
      this.validateEmail();
      this.validatePassword();

      if (this.emailError || this.passwordError) return;

      try {
        this.isLoggingIn = true;
        const response = await this.login(this.account);

        if (response && response.data && response.data.success && response.data.success.token) {
          // Assuming the token is what determines a successful login
          // Store the token, if necessary
          localStorage.setItem('token', response.data.success.token);

          // Store the selected company in local storage
          localStorage.setItem('company', this.account.company);

          // Store the user ID in local storage
          localStorage.setItem('user_id', response.data.success.user_id);

          // Redirect to /dashboard
          const currentDomain = window.location.origin;
          const newPath = '/';
          window.location.href = `${currentDomain}${newPath}`;
        } else {
          // Handle non-successful response
          this.apiError = 'Login failed, please try again.';
        }
      } catch (error) {
        console.error('Error during login:', error);
        this.apiError = 'Unknown error occurred, please try again later.';
      } finally {
        this.isLoggingIn = false;
      }
    }
  },
  data() {
    return {
      account: {
        email: '',
        password: '',
        company: '1' // Default to Focus
      },
      emailError: '',
      passwordError: '',
      apiError: '',
      isLoggingIn: false,
      showMessage: true,
      messageTimeout: null,
    };
  },
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.$router.push('/');
    }
    if (this.message) {
      this.messageTimeout = setTimeout(() => {
        this.showMessage = false;
      }, 3500);
    }
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
  }
};
</script>

<style>
.v-enter-active, .v-leave-active {
  transition: opacity 3s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

.btn-custom-color {
  background-color: #fbbb05; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}

.txt-custom-color {
  color: #fbbb05; /* Set text color to ensure visibility */
}

.btn-custom-color:hover {
  background-color: #e0a800; /* Slightly darker for hover effect */
}

.login-custom-color {
  background-color: #696969; /* Your desired color */
  color: white; /* Set text color to ensure visibility */
}

.login-custom-color:hover {
  background-color: #4c4c4c; /* Slightly darker for hover effect */
}

/* Add styles for the disabled state */
.btn-custom-color:disabled {
  background-color: #fbbb05; /* Maintain the background color */
  color: white; /* Maintain the text color */
  opacity: 0.7; /* Optional: Lower opacity to indicate it's disabled */
  cursor: not-allowed; /* Optional: Change cursor to indicate it's not clickable */
}

.backimg {
  opacity: 0.30;
  position: fixed; /* Changed from relative to fixed */
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Position it at the left of the viewport */
  width: 100vw; /* Span the entire viewport width */
  height: 100vh; /* Span the entire viewport height */
  z-index: -1; /* Place it behind other content */
  background: url('~@/assets/swirl_crop.jpg') no-repeat center center; /* Center the background */
  background-color: #FFF;
  background-size: cover; /* Cover the entire area of the div */
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh; /* Full viewport height */
  position: relative; /* Ensure it's positioned above the background */
  z-index: 1; /* Higher than the background */
}
</style>
