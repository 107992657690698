import axios from 'axios'

const AccountService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'account/init',
      method: 'post'
    })
  },

  async register (info) {
    return axios.post(axios.defaults.baseURL + 'register', info);
  },

  async contactUs (info) {
    return axios.post(axios.defaults.baseURL + 'contactUs', info);
  },

  async login (info) {
    return axios.post(axios.defaults.baseURL + 'login', info);
  },

  async forgotPassword (email) {
    return axios.post(axios.defaults.baseURL + 'password/email', email);
  },

  async resetPassword (info) {
    return axios.post(axios.defaults.baseURL + 'password/reset', info);
  },

  setUserToken (token) {
    localStorage.setItem('token', token)
  }
}

export default AccountService