<template>
  <div>Loading...</div> <!-- or some loading indicator -->
</template>

<script>
import Oidc from 'oidc-client';
import axios from 'axios';

export default {
  name: 'AuthCallback',
  created() {
    const oidcClient = new Oidc.UserManager({ response_mode: "query" });
    oidcClient.signinRedirectCallback().then(user => {
    console.log('OIDC login successful', user);

      // Send the OIDC token to your Laravel API in the Authorization header
      axios.post(axios.defaults.baseURL + 'auth/oidc', {}, { 
        headers: { 'Authorization': `Bearer ${user.access_token}` }
      }).then(response => {
        // Handle the response from your Laravel API
        console.log('Laravel token received', response.data);

        
        // Store the Laravel token in local storage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user_id', response.data.user_id);

        // Redirect to /dashboard
        const currentDomain = window.location.origin;
        const newPath = '/';
        window.location.href = `${currentDomain}${newPath}`;
      }).catch(error => {
        console.error('Error processing user with Laravel API:', error);
        // Handle errors, e.g., show an error message or redirect to a login page
      });

    }).catch(error => {
      console.error('OIDC login error:', error);
      // Handle errors, e.g., show an error message or redirect to a login page
    });
  },
};
</script>
