<template>
  <div class="backimg"> </div>
  <div>
      <Navigation v-if="hasLaravelToken" />
      <RouterView class="router-view-content" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import Oidc from 'oidc-client';
import axios from 'axios';
import Navigation from './views/components/Navigation.vue';

export default {
  setup() {
      // Check if there is a Laravel token in local storage
      const hasLaravelToken = ref(localStorage.getItem('token')? true: false);
      const performOidcValidation = async () => {
          // Initialize OIDC client
          const oidcClient = new Oidc.UserManager({
          // OIDC client configuration
          });
          try {
              // Check if there is no Laravel token and we need to perform OIDC validation
              if (!hasLaravelToken.value) {
                  // Get the OIDC user
                  const user = await oidcClient.getUser();
                  if (user && user.access_token) {
                      // Send the OIDC token to your Laravel API in the Authorization header
                      await axios.post(axios.defaults.baseURL + 'auth/oidc', {}, {
                          headers: { 'Authorization': `Bearer ${user.access_token}` }
                      });
                      // Store the Laravel token in local storage
                      localStorage.setItem('token', user.access_token);
                      hasLaravelToken.value = true;
                  }
              }
          }
          catch (error) {
              console.error('Error during OIDC validation:', error);
          }
      };
      // Perform OIDC validation on component mount
      onMounted(() => {
          performOidcValidation();
      });
      return {
          hasLaravelToken
      };
  },
  components: { Navigation }
};
</script>


<style scoped>
.backimg {
opacity: 0.30;
position: fixed; /* Changed from relative to fixed */
top: 0; /* Position it at the top of the viewport */
left: 0; /* Position it at the left of the viewport */
width: 100vw; /* Span the entire viewport width */
height: 100vh; /* Span the entire viewport height */
z-index: -1; /* Place it behind other content */
background: url('~@/assets/swirl_crop.jpg') no-repeat center center; /* Center the background */
background-color: #FFF;
background-size: cover; /* Cover the entire area of the div */
}

/* .backimg {
/* Existing styles
} */

.router-view-content {
min-height: calc(100vh - 64px); /* This assumes the nav bar is 64px tall */
width: 100%;
}

</style>