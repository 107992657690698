<template>
  <div class="relative overflow-x-auto">
    <div v-if="isLoading" class="absolute inset-0 z-50">
      <LoadingOverlay :show="isLoading" />
    </div>
    <table v-else class="min-w-full table-auto border-collapse text-sm">
      <thead>
        <tr class="bg-gray-300">
          <th class="px-4 py-2 border" v-for="week in weeks" :key="week">{{ week }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in organizedRows" :key="rowIndex">
          <td class="px-4 py-2 border relative" v-for="(account, colIndex) in row" :key="colIndex">
            <div @click="toggleTooltip(account)" class="relative cursor-pointer">
              <span :class="{ 'text-red-500': isFallingBehind(account) }">
                {{ account ? account.business_name : '' }}
              </span>
              <div v-if="tooltipVisible && hoveredAccount === account" class="absolute left-0 top-full mt-2 w-64 p-2 bg-white border rounded shadow-lg z-10">
                <p><strong>Next Appointment Date:</strong> {{ formatDate(account.next_appointment) }}</p>
                <p><strong>Mood:</strong> {{ account.mood }}</p>
                <p><strong>Status:</strong> {{ account.status }}</p>
                <button @click="navigateToProfile(account.account_id)" class="mt-2 px-4 py-2 text-white bg-blue-500 rounded">Profile</button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue'; // Make sure to import your loading overlay component
import { useRouter } from 'vue-router';
import { format, addWeeks, addDays, isAfter } from 'date-fns'; // Import date-fns

const weeks = ref(['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8']);
const organizedRows = ref([]);
const isLoading = ref(false); // Add loading state
const tooltipVisible = ref(false);
const hoveredAccount = ref(null);
const router = useRouter();

const fetchAccounts = async () => {
  isLoading.value = true; // Start loading
  const orgId = localStorage.getItem('company');
  try {
    const response = await axios.get('/onboarding', {
      params: {
        org_id: orgId,
      },
    });
    const accounts = response.data.map(client => {
      const clientData = client.focus_client || client.choice_client || client.relmedia_client;
      return {
        account_id: client.account_id,
        business_name: clientData.business_name,
        bucket: client.bucket,
        next_appointment: client.next_appointment,
        mood: client.status, // Using status as mood
        status: clientData.status, // Keeping original status
        date_created: client.date_created, // Add date_created
      };
    });

    // Initialize an array to hold accounts organized by buckets
    const bucketRows = Array(8).fill().map(() => []);

    // Organize accounts into their respective buckets
    accounts.forEach(account => {
      const bucketIndex = account.bucket - 1;
      if (bucketIndex >= 0 && bucketIndex < 8) {
        bucketRows[bucketIndex].push(account);
      }
    });

    // Determine the maximum number of rows needed
    const maxRows = Math.max(...bucketRows.map(bucket => bucket.length));

    // Build the rows array for the table
    organizedRows.value = Array.from({ length: maxRows }, (_, rowIndex) =>
      bucketRows.map(bucket => bucket[rowIndex] || null)
    );

  } catch (error) {
    console.error('Error fetching accounts:', error);
  }
  isLoading.value = false; // End loading
};

const toggleTooltip = (account) => {
  if (hoveredAccount.value === account && tooltipVisible.value) {
    tooltipVisible.value = false;
    hoveredAccount.value = null;
  } else {
    hoveredAccount.value = account;
    tooltipVisible.value = true;
  }
};

const navigateToProfile = (accountId) => {
  router.push({ path: `/company/${accountId}` });
};

// Function to format the date
const formatDate = (date) => {
  return format(new Date(date), 'MMMM dd, yyyy h:mm a');
};

// Function to check if an account is falling behind
const isFallingBehind = (account) => {
  if (!account) return false;
  const weeksInBucket = account.bucket;
  const createdDate = new Date(account.date_created);
  const thresholdDate = addDays(addWeeks(createdDate, weeksInBucket), 2);
  return isAfter(new Date(), thresholdDate);
};

onMounted(() => {
  fetchAccounts();
});
</script>

<style scoped>
/* Custom styles for the tooltip */
.tooltip {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 8px;
  width: 256px;
  padding: 8px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>
