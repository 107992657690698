<template>
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center z-50">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Add User
              </h3>
              <div class="mt-2">
                <form @submit.prevent="addUser">
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="first_name">First Name</label>
                    <input v-model="user.first_name" type="text" id="first_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <p v-if="errors.first_name" class="text-red-500 text-xs italic">{{ errors.first_name[0] }}</p>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="last_name">Last Name</label>
                    <input v-model="user.last_name" type="text" id="last_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <p v-if="errors.last_name" class="text-red-500 text-xs italic">{{ errors.last_name[0] }}</p>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label>
                    <input v-model="user.email" type="email" id="email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <p v-if="errors.email" class="text-red-500 text-xs italic">{{ errors.email[0] }}</p>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="phone_number">Phone Number</label>
                    <input v-model="user.phone_number" type="text" id="phone_number" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <p v-if="errors.phone_number" class="text-red-500 text-xs italic">{{ errors.phone_number[0] }}</p>
                  </div>
                  <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="password">Password</label>
                    <input v-model="user.password" type="password" id="password" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <p v-if="errors.password" class="text-red-500 text-xs italic">{{ errors.password[0] }}</p>
                  </div>
                  <div class="flex items-center justify-between">
                    <button type="button" @click="closeModal" class="bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Cancel</button>
                    <button type="submit" class="bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Add User</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  
  const props = defineProps({
    isOpen: Boolean
  });
  
  const emit = defineEmits(['close', 'userAdded']);
  
  const user = ref({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: ''
  });
  
  const errors = ref({});
  
  const addUser = async () => {
    try {
      const response = await axios.post('/users', user.value, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      emit('userAdded', response.data);
      closeModal();
    } catch (error) {
      if (error.response && error.response.status === 422) {
        errors.value = error.response.data.errors;
      } else {
        console.error('Error adding user:', error);
      }
    }
  };
  
  const closeModal = () => {
    emit('close');
    errors.value = {}; // Clear errors when closing the modal
  };
  </script>
  
  <style scoped>
  /* Center the modal */
  .fixed {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the modal background overlay */
  .bg-gray-500 {
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  /* Ensure inputs take up the full width within the modal with some padding */
  .input-container {
    padding: 0 1rem;
  }
  </style>
  