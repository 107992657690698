<template>
  <div class="logged-out-body-container">
    <!-- inclide logo -->

    <div class="background-gradient"></div>&nbsp;<div>

      <div>
        <h1>Your contact form was received!</h1>
      </div>
      <div>
        <p>An administrator will reply to your request soon.</p>
      </div>
    </div>
    <br>

    </div>

</template>

<script>
import AccountService from '@/services/AccountService'

import { useRouter } from 'vue-router';

export default {
  name: 'ForgotPassword',

  data () {
    return {
      account: {
        email: ''
      }
    };
  },
  methods: {
    submit () {
      AccountService.forgotPassword(this.account).then((response) => {
        this.$router.push({ path: '/login', query: {message:'An email was sent to reset your password!'} });
      })
    },

    cancel () {

    }
  }
};
</script>

